import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'


function JobList({ jobs }) {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(window.innerWidth > 768 ? 21 : 10);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setJobsPerPage(window.innerWidth > 768 ? 21 : 10);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000); // Simulate data fetching
  }, []);

  const filteredJobs = jobs.filter(job =>
    job.title.toLowerCase().includes(filter.toLowerCase())
  );

  const totalPageCount = Math.ceil(filteredJobs.length / jobsPerPage);
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  // Calculate visible page numbers
  const maxVisiblePages = 5;
  const visiblePages = [];
  let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
  let endPage = Math.min(startPage + maxVisiblePages - 1, totalPageCount);
  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  const navigation = [
    { name: 'الرئيسية', href: '#' },
    { name: 'الشركات', href: '#' }, // You should replace '#' with the actual path you want to navigate to
  ];
  

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return (
    
    <div className="p-5 bg-white">
      
      <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a
                    href="#"
                    className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    الرئيسية
                  </a>
                  <a
                    href="#"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    الشركات
                  </a>

                </div>
              </div>

            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
              >
                الرئيسية
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                الشركات
              </Disclosure.Button>

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

      <div className="mb-4">
        <input
          type="text"
          className="p-2 border rounded w-full"
          placeholder="Search jobs..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      {/* {isLoading ? (
        <div className="text-center">Loading jobs...</div>
      ) : (
        <div>
          <ul role="list" className="divide-y divide-gray-200">
            {currentJobs.map((job) => (
              <li key={job.id} className="py-4">
                <div className="flex items-center space-x-4">
                  <img className="h-10 w-10 rounded-full" src={job.company_logo} alt={`${job.company_name} logo`} />
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">{job.title}</p>
                    <p className="text-sm text-gray-500 truncate">{job.location}</p>
                  </div>
                  <div>
                    <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                    
<button
        type="button"
        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
قدم الآن      </button>
                    </a>
                    
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )} */}

{/* {isLoading ? (
        <div className="text-center">Loading jobs...</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentJobs.map((job) => (
            <div key={job.id} className="flex flex-col items-center p-4 bg-white shadow-lg rounded-lg">
                <img src={job.company_logo} alt={`${job.company_name} logo`} className="h-16 w-16 rounded-full object-cover mb-4" />
                <h3 className="text-md font-bold mb-1">{job.title}</h3>
                <p className="text-gray-500 text-xs mb-2">Location: {job.location}</p>
                <p className="text-gray-700 text-sm mb-4">{job.job_description.length > 100 ? `${job.job_description.substring(0, 100)}...` : job.job_description}</p>
                <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                    
                    <button
                            type="button"
                            className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                    قدم الآن      </button>
                                        </a>

                
            </div>
          ))}
        </div>
      )} */}


{isLoading ? (
        <div className="text-center">Loading jobs...</div>
      ) : (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {currentJobs.map((job) => (
      <li
      key={job.id}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
    >
      <div className="flex flex-1 flex-col p-8">
        <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={job.company_logo} alt={`${job.company_name} logo`} />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{job.title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{job.company_name}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">

          <p className="text-gray-500 text-xs mb-2">Location: {job.location}</p>
                <p className="text-gray-700 text-sm mb-4">{job.job_description.length > 100 ? `${job.job_description.substring(0, 100)}...` : job.job_description}</p>

          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1 center">


          <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                    
                    <button
                            type="button"
                            className="rounded-full bg-indigo-600 center px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                    قدم الآن      </button>
                                        </a>


          </div>

        </div>
      </div>
    </li>



  
          ))}
            </ul>
        
      )}




      <div className="flex justify-center mt-8">
        {visiblePages.map(number => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`px-4 py-2 border mx-1 ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}

export default JobList;
